<template>
    <div class="main">
        <div>{{ result }}</div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {
            ip: '',
            result: '',
        }
    },
    methods: {
        initData() {
            doRequest('/app/closeSpecialTimeSwitch', {
                ip: this.ip,
            }, this.$route.query).then(() => {
                this.result = '充值已恢复'
            })
        },
    },
    mounted() {
        this.ip = this.$route.query['ip']
        this.initData()
    }
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}
</style>